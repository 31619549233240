<template>
  <el-aside :width="collapse ? '64px' : '200px'" class="layout-aside">
    <div class="aside-header">
      <img v-if="sidebarlogo" :src="sidebarlogo" class="aside-logo-image" />
    </div>
    <el-scrollbar class="aside-scrollbar" wrap-class="aside-scrollbar-wrapper">
      <el-menu class="aside-sidebar-menu" :default-active="activeMenu" :background-color="variables.sidebarMenuBg"
        :text-color="variables.sidebarMenuText" :active-text-color="variables.sidebarMenuActiveText"
        :collapse-transition="false" :collapse="collapse" :router="true">
        <template v-for="route in sidebarmenu">
          <el-menu-item :index="route.url" :key="route.name" v-if="!route.child || route.child.length == 0">
            <img class="sidebar-menu-icon normal" :src="route.iconnormal" alt="">
            <img class="sidebar-menu-icon active" :src="route.iconactive" alt="">
            <span slot="title">{{ route.title }}</span>
          </el-menu-item>
          <el-submenu v-if="route.child && route.child.length > 0" popper-class="aside-sidebar-menu-popper"
            :key="route.name" :index="route.name">
            <template slot="title">
              <img class="sidebar-menu-icon normal" :src="route.iconnormal" alt="">
              <img class="sidebar-menu-icon active" :src="route.iconactive" alt="">
              <span>{{ route.title }}</span>
            </template>
            <el-menu-item v-for="subroute in route.child" :key="subroute.name" :index="subroute.url">{{ subroute.title
            }}
            </el-menu-item>
          </el-submenu>

        </template>

        <!-- <el-submenu index="1" popper-class="aside-sidebar-menu-popper">
            <template slot="title">
              <img class="sidebar-menu-icon normal" src="@/assets/sidebar/jbxx@2x.png" alt="">
              <img class="sidebar-menu-icon active" src="@/assets/sidebar/jbxx-active@2x.png" alt="">
              <span>基本信息</span>
            </template>
            <el-menu-item index="1-1">灾害点管理</el-menu-item>
            <el-menu-item index="1-2">监测点管理</el-menu-item>
          </el-submenu>
          <el-menu-item index="/login/index">
            <img class="sidebar-menu-icon normal" src="@/assets/sidebar/jcsb@2x.png" alt="">
            <img class="sidebar-menu-icon active" src="@/assets/sidebar/jcsb-active@2x.png" alt="">
            <span slot="title">监测设备</span>
          </el-menu-item>
          <el-menu-item index="3">
            <img class="sidebar-menu-icon normal" src="@/assets/sidebar/yjxx@2x.png" alt="">
            <img class="sidebar-menu-icon active" src="@/assets/sidebar/yjxx-active@2x.png" alt="">
            <span slot="title">预警信息</span>
          </el-menu-item>
          <el-menu-item index="4">
            <img class="sidebar-menu-icon normal" src="@/assets/sidebar/api@2x.png" alt="">
            <img class="sidebar-menu-icon active" src="@/assets/sidebar/api-active@2x.png" alt="">
            <span slot="title">API接口</span>
          </el-menu-item>
          <el-menu-item index="5">
            <img class="sidebar-menu-icon normal" src="@/assets/sidebar/dsj@2x.png" alt="">
            <img class="sidebar-menu-icon active" src="@/assets/sidebar/dsj-active@2x.png" alt="">
            <span slot="title">大数据中心</span>
          </el-menu-item>
          <el-menu-item index="6">
            <img class="sidebar-menu-icon normal" src="@/assets/sidebar/xmcy@2x.png" alt="">
            <img class="sidebar-menu-icon active" src="@/assets/sidebar/xmcy-active@2x.png" alt="">
            <span slot="title">项目成员</span>
          </el-menu-item>
          <el-menu-item index="7">
            <img class="sidebar-menu-icon normal" src="@/assets/sidebar/xtsz@2x.png" alt="">
            <img class="sidebar-menu-icon active" src="@/assets/sidebar/xtsz-active@2x.png" alt="">
            <span slot="title">系统设置</span>
          </el-menu-item> -->
      </el-menu>

    </el-scrollbar>

    <div class="aside-footer">
      <div class="aside-fold">
        <span style="cursor: pointer;" v-if="!collapse" @click="onAsideCollapse">
          <i :size="11" class="aside-fold-icon el-icon-arrow-left"></i>收起系统
        </span>
        <span v-else style="cursor: pointer;" @click="onAsideCollapse">
          <i :size="11" class="aside-fold-icon el-icon-arrow-right"></i>
        </span>
      </div>
      <div class="aside-footer-version">
        {{ version }}
      </div>
    </div>
  </el-aside>
</template>
<script>
import { mapGetters } from "vuex";
import variables from "@/style/variables.scss";
export default {
  data() {
    return {
      collapse: false
    }
  },
  computed: {
    ...mapGetters(["sidebarmenu", "sidebarlogo", "logindetail"]),
    version() {
      if (this.collapse) {
        return 'v2.0'
      }
      return this.logindetail ? this.logindetail.SmallTitle : '地质灾害预警系统2.0';
    },
    variables() {
      return variables;
    },
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
  },
  methods: {
    onAsideCollapse() {
      this.collapse = !this.collapse;
    }
  },
  created() {
    // console.log(this.sidebarmenu);
  }
}
</script>