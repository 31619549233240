import Cookies from 'js-cookie'
import { getmenu, login, getUserInfo, getLoginText } from '@/api/user'
import { getSystemDetail } from '@/api/api'

const TokenKey = 'geology-monitor-system-token'
const LoginPageInfoKey = 'geology-monitor-system-loginpageinfo'
const LoginDetailKey = 'geology-monitor-system-logindetail'

const state = {
  token: Cookies.get(TokenKey) ? Cookies.get(TokenKey) : '',
  userinfo: '',
  sidebarlogo: '',
  sidebarmenu: '',
  loginPageInfo: Cookies.get(LoginPageInfoKey) ? JSON.parse(Cookies.get(LoginPageInfoKey)) : '',
  loginDetail: Cookies.get(LoginDetailKey) ? JSON.parse(Cookies.get(LoginDetailKey)) : '',
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
    Cookies.set(TokenKey, token);
  },
  SET_USER: (state, userinfo) => {
    state.userinfo = userinfo;
  },
  SET_SIDEBARLOGO: (state, sidebarlogo) => {
    state.sidebarlogo = sidebarlogo;
  },
  SET_SIDEBARMENU: (state, sidebarmenu) => {
    state.sidebarmenu = sidebarmenu;
  },
  SET_LOGINPAGEINFO: (state, loginPageInfo) => {
    state.loginPageInfo = loginPageInfo;
    Cookies.set(LoginPageInfoKey, JSON.stringify(loginPageInfo));
  },
  SET_LOGINDETAIL: (state, loginDetail) => {
    state.loginDetail = loginDetail;
    Cookies.set(LoginDetailKey, JSON.stringify(loginDetail));
  },
}

const actions = {
  login({ dispatch, commit }, logininfo) {
    return new Promise((resolve, reject) => {
      login(logininfo).then(function (resdata) {
        if (resdata.code == 0) {
          commit('SET_TOKEN', resdata.data);
          //获取用户信息
          dispatch('getuserinfo');
          dispatch('getLoginDetail');
          //获取菜单
          commit('SET_SIDEBARMENU', '');
          dispatch('getmenu').then(function () {
            resolve();
          }).catch(function () {
            reject('权限异常');
          });
        } else {
          reject(resdata.msg);
          return;
        }
      }).catch(function (error) {
        reject(error.message);
      });
    })
  },
  getmenu({ commit, dispatch }, route) {
    return new Promise((resolve, reject) => {
      if (state.sidebarmenu) {
        resolve();
        return;
      }
      getmenu().then(function (res) {
        if (res.code == 0) {
          var menudata = res.data;
          var getChildMenu = function (data) {
            var menuitems = [];
            for (let index = 0; index < data.length; index++) {
              const element = data[index];
              var menuitem = {
                title: element.title,
                name: element.name,
                iconnormal: element.icon,
                iconactive: element.iconactive,
                url: element.href
              }
              if (element.children && element.children.length > 0) {
                var childMenu = getChildMenu(element.children);
                menuitem.child = childMenu
              }
              menuitems.push(menuitem);
            }
            return menuitems;
          }
          var menuitems = getChildMenu(menudata);
          commit('SET_SIDEBARMENU', menuitems);
          resolve();
        } else {
          reject("获取菜单失败");
        }

      }).catch(function (error) {
        reject(error);
      })
      // getmenu().then(function (menudata) {
      //     if(state.userinfo.RoleId!=1){
      //         //menudata = menudata.slice(0,menudata.length-1);
      //     }
      //     commit('SET_SIDEBARMENU', menudata);
      //     resolve();
      // }).catch(function (error) {
      //     reject(error);
      // })
    })
  },
  getuserinfo({ commit }) {
    return new Promise((resolve, reject) => {
      getUserInfo().then(res => {
        if (res.code == 0) {
          commit('SET_USER', res.data.user);
          commit('SET_SIDEBARLOGO', res.data.logo);
          resolve();
        } else {
          reject();
        }
      }).catch(() => {
        reject();
      })
    })
  },
  logout({ commit }) {
    commit('SET_TOKEN', '');
  },
  getLoginPageInfo({ commit }, orgcode) {
    return new Promise((resolve, reject) => {
      var isexist = false;
      if (orgcode && state.loginPageInfo) {
        if (state.loginPageInfo.LoginCode == orgcode) {
          isexist = true;
          resolve(state.loginPageInfo);
        }
      }
      getLoginText(orgcode).then(res => {
        if (res.code == 0) {
          commit('SET_LOGINPAGEINFO', res.data);
          if (!isexist) {
            resolve(res.data);
          }
        } else {
          reject();
        }
      }).catch(() => {
        reject();
      })
    })
  },
  getLoginDetail({ commit }) {
    return new Promise((resolve, reject) => {
      getSystemDetail().then(res => {
        if (res.code == 0) {
          commit('SET_LOGINDETAIL', res.data);
          resolve(res.data);
        } else {
          reject();
        }
      }).catch(() => {
        reject();
      })
    })
  }
}


export default {
  namespaced: true,
  state,
  mutations,
  actions
}