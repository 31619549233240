const menudata = [
    {
        title: '首页',
        name: 'home',
        iconnormal: require('@/assets/sidebar/sy@2x.png'),
        iconactive: require('@/assets/sidebar/sy-active@2x.png'),
        url: '/main/index',
    },{
        title: '基本信息',
        name: 'jbxx',
        iconnormal: require('@/assets/sidebar/jbxx@2x.png'),
        iconactive: require('@/assets/sidebar/jbxx-active@2x.png'),
        url: '/danger/index',
        child: [{
            title: '监测点管理',
            name: 'danger',
            url: '/danger/index'
        },{
            title: '监测点位管理',
            name: 'monitor',
            url: '/monitor/index'
        },{
            title: '承建单位管理',
            name: 'contractor',
            url: '/company/contractor'
        },{
            title: '运维单位管理',
            name: 'maintain',
            url: '/company/maintain'
        },{
            title: '人员管理',
            name: 'maintainuser',
            url: '/company/maintainuser'
        }]
    },
    {
        title: '监测设备',
        name: 'jcsb',
        iconnormal: require('@/assets/sidebar/jcsb@2x.png'),
        iconactive: require('@/assets/sidebar/jcsb-active@2x.png'),
        url: '/device/index',
        child: [{
            title: '设备管理',
            name: 'device',
            url: '/device/index'
        },{
            title: '监测报告',
            name: 'device-report',
            url: '/device/report'
        },{
            title: '巡查管理',
            name: 'device-inspect',
            url: '/device/inspect'
        },{
            title: '命令下发',
            name: 'device-command',
            url: '/device/command'
        },{
            title: '在线升级',
            name: 'device-upgrade',
            url: '/device/upgrade'
        }]
    },
    {
        title: '预警管理',
        name: 'yjxx',
        iconnormal: require('@/assets/sidebar/yjxx@2x.png'),
        iconactive: require('@/assets/sidebar/yjxx-active@2x.png'),
        url: '/warning/index',
        child: [{
            title: '预警信息',
            name: 'warning',
            url: '/warning/index'
        },{
            title: '预警模型',
            name: 'warning-model',
            url: '/warning/model'
        },{
            title: '预警推送',
            name: 'warning-push',
            url: '/warning/push'
        }]
    },
    {
        title: '业务配置',
        name: 'api',
        iconnormal: require('@/assets/sidebar/api@2x.png'),
        iconactive: require('@/assets/sidebar/api-active@2x.png'),
        url: '/api/apitoken',
        child: [{
            title: '大屏设置',
            name: 'api-token',
            url: '/api/raydataconfig'
        },{
            title: '开发接口',
            name: 'api-token',
            url: '/api/apitoken'
        },{
            title: '推送配置',
            name: 'api-pushconfig',
            url: '/api/pushconfig'
        }]
    },
    // {
    //     title: '大数据展示',
    //     name: 'dsj',
    //     iconnormal: require('@/assets/sidebar/dsj@2x.png'),
    //     iconactive: require('@/assets/sidebar/dsj-active@2x.png'),
    //     url: '/main4/index',
    // },
    // {
    //     title: '项目成员',
    //     name: 'xmcy',
    //     iconnormal: require('@/assets/sidebar/xmcy@2x.png'),
    //     iconactive: require('@/assets/sidebar/xmcy-active@2x.png'),
    //     url: '/main5/index'
    // },
    {
        title: '系统管理',
        name: 'xtsz',
        iconnormal: require('@/assets/sidebar/xtsz@2x.png'),
        iconactive: require('@/assets/sidebar/xtsz-active@2x.png'),
        url: '/system/syslog',
        child: [{
            title: '系统日志',
            name: 'system-syslog',
            url: '/system/syslog'
        },{
            title: '角色管理',
            name: 'system-sysrole',
            url: '/system/sysrole'
        },{
            title: '用户管理',
            name: 'system-sysuser',
            url: '/system/sysuser'
        },{
            title: '字典分类',
            name: 'system-dictype',
            url: '/system/dictype'
        },{
            title: '字典详情',
            name: 'system-dictionary',
            url: '/system/dictionary'
        },{
            title: '菜单管理',
            name: 'system-sysmenu',
            url: '/system/sysmenu'
        }]
    }
    
];

export default menudata