import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'normalize.css/normalize.css'

import './style/theme/index.css'
import Element from 'element-ui'
Vue.use(Element)

import extconfig from './common/extconfig'
Vue.use(extconfig)

import '@/style/index.scss' // global css

//svgicon图标
import svgicon from './components/svgicon'
Vue.use(svgicon)


Vue.config.productionTip = false

router.beforeEach(async (to, from, next) => {

  const logindetail = store.getters.logindetail;
  const pagetitle = logindetail ? logindetail.LineText1 : '地质灾害监测预警系统';

  document.title = to.meta.title ? to.meta.title + ' - ' + pagetitle : pagetitle;

  if (to.meta.auth === false) {
    next();
    return;
  }
  const token = store.getters.token;
  if (token && token != '') {
    const userinfo = store.getters.userinfo;
    try {
      if (!userinfo) {
        await store.dispatch('user/getuserinfo');
      }
      await store.dispatch('user/getmenu', to);
      next()
    } catch (error) {
      store.dispatch('user/logout');
      next(`/login?redirect=${to.path}`);
    }

  } else {
    if (to.path == '/login') {
      next()
    } else {
      next(`/login?redirect=${to.path}`)
    }
  }
})


Vue.filter('datetime', function (value, fmt) {
  if (!value) return ''
  value = value.replace('T', ' ');
  if (fmt) {
    var date = new Date(value);
    var padLeftZero = function (str) {
      return ('00' + str).substr(str.length);
    }
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    let o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds()
    };
    for (let k in o) {
      if (new RegExp(`(${k})`).test(fmt)) {
        let str = o[k] + '';
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
      }
    }
    return fmt;

  }
  return value;
})

Vue.prototype.$formatDate = function (date, fmt) {
  var padLeftZero = function (str) {
    return ('00' + str).substr(str.length);
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
    }
  }
  return fmt;
}


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
