import menudata from '@/router/menudata'
import request from '@/common/request'


export function getmenu(){
  return request.post('/api/SysMenu/GetModuleList','');
  // return new Promise((resolve, reject) =>{
  //   resolve(menudata);
  // });
}

export function login(logindata){
  return request.post('/api/Login/Login',logindata)
}

export function getLoginText(orgcode){
  return request.post('/api/Login/LoginInfo?orgcode='+orgcode,'')
}

export function getUserInfo(){
  return request.post('/api/AdminInfo/Info','')
}

export function editUserPass(oldpwd,newpwd){
  return request.post('/api/AdminInfo/EditPwd',{
    oldpwd:oldpwd,
    newpwd:newpwd
  })
}

//获取顶部信息条数
export function getMsgCount(){
  return request.post('/api/MainHome/InfoCount','')
}

//获取首页预警信息
export function getWaringMsgAndCount(){
  return request.post('/api/MainHome/WaringMsgAndCount','')
}

//获取首页设备数据
export function getHomeDeviceCount(){
  return request.post('/api/MainHome/DeviceOffLineCount','')
}

//获取首页地图数据
export function getMonitorDeviceList(){
  return request.post('/api/MainHome/MonitorDeviceList','')
}

//获取首页设备相关图表数据
export function getDeviceLineData(deviceid){
  return request.post('/api/DeviceData/EasyDeviceLineDataList',{
    deviceid:deviceid
  })
}

/**
 * 获取首页设备占比
 * @returns 
 */
export function getHomeDeviceRadio() {
    return request.post('/api/MainHome/DeviceScale', '')
}