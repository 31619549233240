import { getItemsDetail } from '@/api/system'

const state = {
    DangerType: [],
    DangerLevel: [],
    MonitorLevel: [],
    DeviceType: [],
    WarningType: [],
    CommandType: [],
    DbType:[],
}

const mutations = {
    SET_DANGERTYPE: (state, DangerType) => {
        state.DangerType = DangerType;
    },
    SET_DANGERLEVEL: (state, DangerLevel) => {
        state.DangerLevel = DangerLevel;
    },
    SET_MONITORLEVEL: (state, MonitorLevel) => {
        state.MonitorLevel = MonitorLevel;
    },
    SET_DEVICETYPE: (state, DeviceType) => {
        state.DeviceType = DeviceType;
    },
    SET_WARNINGTYPE: (state, WarningType) => {
        state.WarningType = WarningType;
    },
    SET_COMMANDTYPE: (state, CommandType) => {
        state.CommandType = CommandType;
    },
    SET_DBTYPE: (state, DbType) => {
        state.DbType = DbType;
    },
}

const actions = {
    getDangerType({ commit }) {
        return new Promise((resolve, reject) => {
            if(state.DangerType.length>0){
                resolve(state.DangerType);
                return;
            }
            getItemsDetail('zhlx').then(function (resdata) {
                if (resdata.code == 0) {
                    commit('SET_DANGERTYPE', resdata.data);
                    resolve(resdata.data);
                } else {
                    reject([]);
                }
            }).catch(function () {
                reject([]);
            });
        })
    },
    getDangerLevel({ commit }) {
        return new Promise((resolve, reject) => {
            if(state.DangerLevel.length>0){
                resolve(state.DangerLevel);
                return;
            }
            getItemsDetail('zhddj').then(function (resdata) {
                if (resdata.code == 0) {
                    commit('SET_DANGERLEVEL', resdata.data);
                    resolve(resdata.data);
                } else {
                    reject([]);
                }
            }).catch(function () {
                reject([]);
            });
        })
    },
    getMonitorLevel({ commit }) {
        return new Promise((resolve, reject) => {
            if(state.MonitorLevel.length>0){
                resolve(state.MonitorLevel);
                return;
            }
            getItemsDetail('jcjb').then(function (resdata) {
                if (resdata.code == 0) {
                    commit('SET_MONITORLEVEL', resdata.data);
                    resolve(resdata.data);
                } else {
                    reject([]);
                }
            }).catch(function () {
                reject([]);
            });
        })
    },
    getDeviceType({ commit }) {
        return new Promise((resolve, reject) => {
            if(state.DeviceType.length>0){
                resolve(state.DeviceType);
                return;
            }
            getItemsDetail('sblx').then(function (resdata) {
                if (resdata.code == 0) {
                    commit('SET_DEVICETYPE', resdata.data);
                    resolve(resdata.data);
                } else {
                    reject([]);
                }
            }).catch(function () {
                reject([]);
            });
        })
    },
    getWarningType({ commit }) {
        return new Promise((resolve, reject) => {
            if(state.WarningType.length>0){
                resolve(state.WarningType);
                return;
            }
            getItemsDetail('yjlx').then(function (resdata) {
                if (resdata.code == 0) {
                    commit('SET_WARNINGTYPE', resdata.data);
                    resolve(resdata.data);
                } else {
                    reject([]);
                }
            }).catch(function () {
                reject([]);
            });
        })
    },
    getCommandType({ commit }) {
        return new Promise((resolve, reject) => {
            if(state.CommandType.length>0){
                resolve(state.CommandType);
                return;
            }
            getItemsDetail('mllx').then(function (resdata) {
                if (resdata.code == 0) {
                    commit('SET_COMMANDTYPE', resdata.data);
                    resolve(resdata.data);
                } else {
                    reject([]);
                }
            }).catch(function () {
                reject([]);
            });
        })
    },
    getDbType({ commit }) {
        return new Promise((resolve, reject) => {
            if (state.DbType.length > 0) {
                resolve(state.DbType);
                return;
            }
            getItemsDetail('dbtype').then(function (resdata) {
                if (resdata.code == 0) {
                    commit('SET_DBTYPE', resdata.data);
                    resolve(resdata.data);
                } else {
                    reject([]);
                }
            }).catch(function () {
                reject([]);
            });
        })
    },
}


export default {
    namespaced: true,
    state,
    mutations,
    actions
}