import request from '@/common/request'

export function getApiTokenDetail() {
  return request.post('/api/ApiToken/ApiTokenDetail', '');
}

export function editApiToken(postdata) {
  return request.post('/api/ApiToken/EditApiToken', postdata);
}

export function getSystemDetail() {
  return request.post('/api/SysLogin/LoginDetail', '');
}

/**
 * 推送配置
 */
export function getPushList(pageindex, pagesize, filter) {
  return request.post('/api/PushConfig/ListPush', Object.assign({
    pageindex: pageindex,
    pagesize: pagesize ? pagesize : 20
  }, filter));
}

export function delPush(id) {
  return request.post('/api/PushConfig/DeletePush/' + id, '');
}

export function addPush(postdata) {
  return request.post('/api/PushConfig/AddtPush', postdata);
}

export function editPush(postdata) {
  return request.post('/api/PushConfig/EditPush', postdata);
}

export function getPushDeviceList(pushid, pageindex) {
  return request.post('/api/PushConfig/ListPushDevice?pageindex=' + pageindex + '&pushid=' + pushid, '');
}

export function delPushDevice(id) {
  return request.post('/api/PushConfig/DeletePushDevice/' + id, '');
}

export function addPushDevice(postdata) {
  return request.post('/api/PushConfig/AddtPushDevice', postdata);
}

export function editPushDevice(postdata) {
  return request.post('/api/PushConfig/EditPushDevice', postdata);
}

//大屏配置
export function getBigData() {
  return request.post('/api/BigData/BigDetail', '');
}
export function setBigData(postdata) {
  return request.post('/api/BigData/SetBigDetail', postdata);
}
export function getBigAreaData() {
  return request.post('/api/BigData/BigArea', '');
}