import Vue from 'vue'
import Vuex from 'vuex'
import global from './modules/global'
import user from './modules/user'
import dictionary from './modules/dictionary'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    global,
    user,
    dictionary
  },
  getters: {
    token: state => state.user.token,
    sidebarmenu: state => state.user.sidebarmenu,
    userinfo: state => state.user.userinfo,
    sidebarlogo: state => state.user.sidebarlogo,
    keepaliveinclude: state => state.global.KeepAliveInclude,
    logindetail: state => state.user.loginDetail,
  }
})
