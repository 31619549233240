
const state = {
    MaintainUserInfo: '',
    KeepAliveInclude:[],
}

const mutations = {
    SET_MAINTAINUSERINFO: (state, MaintainUserInfo) => {
        state.MaintainUserInfo = MaintainUserInfo;
    },
    SET_KEEPALIVEINCLUDE: (state, KeepAliveInclude) => {
        state.KeepAliveInclude = KeepAliveInclude;
    },
}

const actions = {
    setMaintainUserInfo({ commit }, MaintainUserInfo) {
        commit('SET_MAINTAINUSERINFO', MaintainUserInfo);
    },
    addInclude({ commit, state },name){
        let includeData = state.KeepAliveInclude;
        if (includeData.indexOf(name)<0){
            includeData.push(name);
            commit('SET_KEEPALIVEINCLUDE', includeData);
        }
    },
    removeInclude({ commit, state }, name) {
        let includeData = state.KeepAliveInclude;
        let index = includeData.indexOf(name);
        if (index > -1) {
            includeData.splice(name);
            commit('SET_KEEPALIVEINCLUDE', includeData);
        }
    }
}


export default {
    namespaced: true,
    state,
    mutations,
    actions
}