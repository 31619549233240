<template>
  <div class="page-breadcrumb">
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <transition-group name="breadcrumb">
          <template v-for="(item,index) in breadcrumbData">
            <el-breadcrumb-item :key="item.path" v-if="item.meta.title!=''">
              <router-link
                :to="item.path"
                v-if="(index+1)!=breadcrumbData.length"
              >{{item.meta.title}}</router-link>
              <span v-else>{{item.meta.title}}</span>
            </el-breadcrumb-item>
          </template>
        </transition-group>
      </el-breadcrumb>

  </div>
</template>
<script>
export default {
    
  computed: {
    breadcrumbData() {
        console.log(this.$route.matched);
      return this.$route.matched;
    },
  },
    created(){

    }
}
</script>