const getDefConfig = function(){
    if(window.config){
        return window.config;
    }
}
const Extconfig = {
    getConfig(key,defvalue){
        var config = getDefConfig();
        if(config && config[key]){
            return config[key];
        }
        return defvalue;
    }
}

const install = function(Vue){
    if(install.installed) return;
    install.installed = true;

    Vue.Extconfig = Extconfig;

    Vue.prototype.$extconfig = Extconfig;
}

export default {
    install,
    getConfig:Extconfig.getConfig
}