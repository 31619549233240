import SvgIcon from './svgicon'


export default {
  install(Vue){
    const requireAll = requireContext => requireContext.keys().map(requireContext)
    const req = require.context('../../assets/icons', false, /\.svg$/)
    requireAll(req)

    Vue.component('svg-icon', SvgIcon)
  }
}