import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { MessageBox, Message } from 'element-ui'
import ExtConfig from '@/common/extconfig'


const service = axios.create({
    baseURL: ExtConfig.getConfig('apibase','/api'), // url = base url + request url
    timeout: 30000, // request timeout
    withCredentials:true,
    headers:{
        'X-Requested-With':'XMLHttpRequest',
    }
});


// 添加请求拦截器
service.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    if (store.getters.token) {
        config.headers['auth'] = store.getters.token;
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
service.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    const resdata = response.data;
    //402token过期 401无权限
    if (resdata.code == -2 && window.isshowlogintip!=true) {
        window.isshowlogintip = true;
        MessageBox.alert('登录超时，请重新登录。','', {
            confirmButtonText: '立即登录',
            type: 'warning',
            callback: ()=>{
                window.isshowlogintip = false;
                store.dispatch('user/logout');
                if(router.history.current.path!='/login'){
                    router.push('/login?redirect='+ escape(router.history.current.fullPath));
                }
            }
        })
        return Promise.reject('登录超时');
    }
    return response;
}, function (error) {
    // 对响应错误做点什么
    Message.error(error.message);
    return Promise.reject(error);
});

const post = function (url, data, type = 'json', header='') {
    let headers = {
        'Content-Type': 'application/json'
    };
    if(type=='form'){
        headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }
    if(type=='file'){
        headers['Content-Type'] = 'multipart/form-data';
    }


    if(header!=null && header!=''){
        headers = Object.assign(headers,header)
    }
    return new Promise((resolve, reject) => {
        service.post(url, data, {
            transformRequest: [function (data) {
                if (type == 'json') {
                    return JSON.stringify(data);
                }
                if (type == 'file') {
                    return data;
                }
                // 将数据转换为表单数据
                let rets = []
                for (let it in data) {
                    rets.push(encodeURIComponent(it) + '=' + encodeURIComponent(data[it]));
                }
                return rets.join('&');
            }],
            headers: headers
        }).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        });
    })
}

const get = function (url, data, header='') {
    let headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
    };
    if(header!=null && header!=''){
        headers = Object.assign(headers,header)
    }
    return new Promise((resolve, reject) => {
        service.get(url, {
            params: data,
            headers: headers
        }).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        });
    })
}

const downfile = function(url,data) {
    const options = {
        url:url,
        method: 'get',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        params: data,
        responseType:'blob',
    };
    return new Promise((resolve, reject) => {
        service(options).then(response=>{
            if(response.status==200){
                var disposition = response.headers['content-disposition'];
                var filenameArr = disposition.match(/filename=(.*)/);
                var filename = Date.parse(new Date())+".csv";
                if(filenameArr.length==2){
                    filename = filenameArr[1];
                }
                let blob = new Blob([response.data]);
                if (window.navigator.msSaveOrOpenBlob) {
                    navigator.msSaveBlob(blob, filename);
                } else {
                    let link = document.createElement("a");
                    let evt = document.createEvent("HTMLEvents");
                    evt.initEvent("click", false, false);
                    link.href = URL.createObjectURL(blob);
                    link.download = filename;
                    link.style.display = "none";
                    document.body.appendChild(link);
                    link.click();
                    window.URL.revokeObjectURL(link.href);
                }
            }
            resolve();
        }).catch(function (error) {
            reject(error);
        });
    })
    

    
}

export default {
    axios: service,
    post: post,
    get: get,
    downfile: downfile,
}