import request from '@/common/request'

export function getSyslogList(pageindex, pagesize, filter) {
  return request.post('/api/SysLog/List', Object.assign({
    pageindex: pageindex,
    pagesize: pagesize ? pagesize : 20
  }, filter));
}

export function delSyslog(ids) {
  return request.post('/api/SysLog/BatchDel?ids=' + ids, {
  }, 'form');
}

/**
 * 用户管理
 */
export function getSysuserList(pageindex, pagesize, filter) {
  return request.post('/api/Users/ListUser', Object.assign({
    pageindex: pageindex,
    pagesize: pagesize ? pagesize : 20
  }, filter));
}
export function addSysuser(postdata) {
  return request.post('/api/Users/AddUser', postdata);
}
export function editSysuser(postdata) {
  return request.post('/api/Users/EditUser', postdata);
}
export function delSysuser(id) {
  return request.post('/api/Users/DeleteUser/' + id, '');
}

export function setSysuserState(id, state) {
  return request.post('/api/Users/EnabledUser', {
    id: id,
    enabled: state
  });
}

/**
 * 角色管理
 */
export function getSysroleList() {
  return request.post('/api/Role/RoleList', {});
}
export function addSysrole(postdata) {
  return request.post('/api/Role/AddRole', postdata);
}
export function editSysrole(postdata) {
  return request.post('/api/Role/EditRole', postdata);
}
export function delSysrole(id) {
  return request.post('/api/Role/DeleteRole/' + id, '');
}

/**
 * 字典分类管理
 */
export function getDictypeList() {
  return request.post('/api/Items/ItemList', {});
}
export function addDictype(postdata) {
  return request.post('/api/Items/AddItem', postdata);
}
export function editDictype(postdata) {
  return request.post('/api/Items/EditItem', postdata);
}
export function delDictype(id) {
  return request.post('/api/Items/DeleteItem/' + id, '');
}

/**
 * 字典详情管理
 */
export function getDictionaryList(pageindex, pagesize, filter) {
  return request.post('/api/Items/DetailList', Object.assign({
    pageindex: pageindex,
    pagesize: pagesize ? pagesize : 20
  }, filter));
}
export function addDictionary(postdata) {
  return request.post('/api/Items/AddItemsDetail', postdata);
}
export function editDictionary(postdata) {
  return request.post('/api/Items/EditItemsDetail', postdata);
}
export function getItemsDetail(itemcode) {
  return request.post('/api/Items/DetailListCode?itemcode=' + itemcode, '');
}
export function delDictionary(id) {
  return request.post('/api/Items/DeleteItemsDetail/' + id, '');
}

/**
 * 菜单管理
 */
export function getSysmenuList(pageindex, pagesize, filter) {
  return request.post('/api/SysMenu/ListMenu', {});
}
export function addSysmenu(postdata) {
  return request.post('/api/SysMenu/AddMenu', postdata);
}
export function editSysmenu(postdata) {
  return request.post('/api/SysMenu/EditMenu', postdata);
}
export function delSysmenu(id) {
  return request.post('/api/SysMenu/DeleteMenu/' + id, '');
}
export function setRoleSysmenu(roleid, postdata) {
  return request.post('/api/SysMenu/InsertBatch?roleId=' + roleid, postdata);
}
export function getRoleSysmenu(roleid) {
  return request.post('/api/SysMenu/RoleMenuList?roleid=' + roleid, '');
}

/**
 * 系统信息
 */
export function getSysinfoList(pageindex, pagesize, filter) {
  return request.post('/api/SysInfo/List', Object.assign({
    pageindex: pageindex,
    pagesize: pagesize ? pagesize : 20
  }, filter));
}
export function updateSysinfoState(id) {
  return request.post('/api/SysInfo/UpdateInfoState?sid=' + id, '');
}
export function delSysinfo(ids) {
  return request.post('/api/SysLog/BatchDel?ids=' + ids, {
  }, 'form');
}

/**
 * 登录信息设置
 */
export function getLoginDetail(userid) {
  return request.post('/api/SysLogin/LoginDetail?userid=' + userid, {
  }, 'form');
}
export function updateLoginDetail(postdata) {
  return request.post('/api/SysLogin/EditLoginInfo', postdata);
}