<template>
  <div class="layout-header">
    <div class="header-title">{{ title }}</div>
    <div class="header-right">
      <!-- <img src="@/assets/header-weather.png" class="header-weather-image" />
      <div class="header-weather-content">
        <div class="text">今天</div>
        <div class="temp">12-18℃</div>
      </div> -->
      <el-badge :value="msgCount" style="margin-right: 30px">
        <div class="header-message-tips" @click="onToMsginfo">
          <i class="el-icon-message-solid"></i>
        </div>
      </el-badge>

      <el-dropdown trigger="click" @command="handleDropdown">
        <div class="header-user-actions">
          <el-avatar :size="26"
            :src="userinfo.HeadIcon ? userinfo.HeadIcon : require('@/assets/default-user-avatar.png')"></el-avatar>
          <div class="username">{{ userinfo.RealName }}</div>
          <i class="el-icon-caret-bottom"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item command="center">个人中心</el-dropdown-item> -->
          <el-dropdown-item command="editpass">修改密码</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <img src="@/assets/icon-logout.png" class="icon-logout" @click="onLogout" />
    </div>

    <el-dialog title="修改密码" width="450px" custom-class="geology-dialog" :visible.sync="editPassDialogVisible"
      :modal-append-to-body="false" :before-close="editPassFormClose" :destroy-on-close="true"
      :close-on-click-modal="false">
      <el-form class="geology-form" label-width="80px" style="padding-top:30px;" ref="editPassForm"
        :model="editPassForm" :rules="editPassFormRules">
        <el-form-item label="旧密码" prop="oldpass">
          <el-col :span="20">
            <el-input size="small" type="password" v-model="editPassForm.oldpass" autocomplete="off"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="新密码" prop="newpass">
          <el-col :span="20">
            <el-input size="small" type="password" v-model="editPassForm.newpass" autocomplete="off"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="确认密码" prop="newpass2">
          <el-col :span="20">
            <el-input size="small" type="password" v-model="editPassForm.newpass2" autocomplete="off"></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain size="small" @click="editPassFormClose">取 消</el-button>
        <el-button size="small" type="primary" @click="dialogEditPassSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { editUserPass, getMsgCount } from '@/api/user'
import ExtConfig from '@/common/extconfig'

export default {
  data() {
    return {
      msgCount: 0,
      editPassDialogVisible: false,
      editPassForm: {
        oldpass: "",
        newpass: "",
        newpass2: "",
      },
      editPassFormRules: {
        oldpass: [{ required: true, message: "请输入旧密码", trigger: "blur" }],
        newpass: [{ required: true, message: "请输入新密码", trigger: "blur" }],
        newpass2: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("请再次输入密码"));
              } else if (value !== this.editPassForm.newpass) {
                callback(new Error("两次输入的密码不一致!"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["userinfo", "logindetail"]),
    title() {
      return this.logindetail ? this.logindetail.LineText1 : '地质灾害监测预警系统';
    }
  },
  methods: {
    onLogout() {
      this.$store.dispatch("user/logout");
      this.$router.push("/login");
    },
    handleDropdown(command) {
      if (command == "editpass") {
        this.editPassDialogVisible = true;
      }
    },
    editPassFormClose() {
      this.editPassDialogVisible = false;
      this.$refs["editPassForm"].resetFields();
    },
    dialogEditPassSubmit() {
      this.$refs["editPassForm"].validate((valid) => {
        if (valid) {
          editUserPass(this.editPassForm.oldpass, this.editPassForm.newpass)
            .then((res) => {
              if (res.code == 0) {
                this.editPassDialogVisible = false;
                this.$refs["editPassForm"].resetFields();
                this.$message({
                  type: "success",
                  message: "修改成功!",
                });
              } else {
                this.$message({
                  type: "error",
                  message: "修改失败！" + res.msg,
                });
              }
            })
            .catch((errmsg) => {
              this.$message({
                type: "error",
                message: "修改失败！" + errmsg,
              });
            });
        } else {
          return false;
        }
      });
    },
    getMsgCount() {
      getMsgCount().then(res => {
        if (res.code == 0) {
          this.msgCount = res.data;
        }
      })
    },
    onToMsginfo() {
      this.$router.push('/system/sysinfo')
    }
  },
  created() {
    this.getMsgCount();
  }
};
</script>